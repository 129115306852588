import {StorageService} from "./storage.service";


class ShopConfigServiceController {

    public setShopId(shopId:string){
        StorageService.set('shopId',shopId)
    }

    public getShopId(){
        return StorageService.get('shopId')
    }



}


export const ShopConfigService = new ShopConfigServiceController();
